<template>

  <q-page
    class="window-height window-width row justify-center items-center bg-primary">

    <AuthCardForm @submit="send">

      <BaseInput
        color="primary"
        label="Scegli una nuova password"
        dark
        clearable
        clear-icon="mdi-close"
        no-error-icon
        error-name="password"
        :errors="errors"
        :stack-label="false"
        v-model="password"
        :type="showPassword ? 'text' : 'password'"
        :rules="[val => !!val || 'Campo Obbligatorio']">

        <template #prepend>
          <q-icon name="mdi-lock" />
        </template>
        <template #append>
          <q-icon
            :name="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            class="cursor-pointer"
            @click="togglePassword"
          />
        </template>
      </BaseInput>

      <BaseInput
        color="primary"
        label="Conferma la nuova password"
        dark
        clearable
        clear-icon="mdi-close"
        no-error-icon
        error-name="password_confirmation"
        :errors="errors"
        :stack-label="false"
        v-model="password_confirmation"
        :type="showPasswordConfirmation ? 'text' : 'password'"
        :rules="[val => !!val || 'Campo Obbligatorio']">

        <template #prepend>
          <q-icon name="mdi-lock" />
        </template>
        <template #append>
          <q-icon
            :name="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
            class="cursor-pointer"
            @click="togglePasswordConfirmation"
          />
        </template>
      </BaseInput>

      <div class="row justify-end">

        <BaseBtn
          type="submit"
          :disabled="loading"
          label="Salva"
          class="q-mt-sm full-width q-pa-sm"
          color="primary" />

      </div>

      <div class="text-negative" v-if="errors.hasError( 'email' )">
        {{ errors.getError( 'email' ) }}

        <a href="#" @click.prevent="forgotPassword" class="text-white">Invia di nuovo</a>
      </div>

    </AuthCardForm>

    <BaseInnerLoading :showing="loading" />

  </q-page>

</template>

<script>
import AuthCardForm from '../components/auth/AuthCardForm.vue'
import useApiErrors from '../hooks/apiErrors.js'
import useAuth from '../hooks/auth.js'
import useNotify from '../hooks/notify.js'
import useToggler from '../hooks/toggler.js'
import { ref } from '@vue/composition-api'
import { router } from '../config/router.js'

export default {
  name: 'ResetPassword',

  components: {
    AuthCardForm,
  },

  setup(_, {root}){
    const { toggler : loading, on: showLoader, off: hideLoader } = useToggler()
    const { toggler : showPassword, toggle: togglePassword } = useToggler()
    const { toggler : showPasswordConfirmation, toggle: togglePasswordConfirmation } = useToggler()
    const { passwordReset, forgotPassword } = useAuth()
    const { success } = useNotify()

    const errors = useApiErrors()
    const password = ref('')
    const password_confirmation = ref('')

    function send()
    {
      showLoader()

      passwordReset( root.$route.query.email, root.$route.query.token, password.value, password_confirmation.value )
        .then( response => {
          success( response.message )
          router.push( { name : 'login' } )
        })
        .catch( errors.setError )
        .finally( hideLoader )
    }

    return {
      errors,
      loading,
      forgotPassword,
      password,
      password_confirmation,
      showPassword,
      showPasswordConfirmation,
      togglePassword,
      togglePasswordConfirmation,
      send,
    }
  },
}
</script>

